import { PaymentMethodContinue } from 'utils/enums/PaymentMethodContinue'
import { getPath } from 'utils/getPath'
import { Page } from '~/cms/front/core/pages/Page'
import component from './Summary.vue'

class SummaryPage extends Page {
	static alias = 'summary'
	showCheckoutProgressBar = true
	component = component
	draftOrder
	error = false
	deliveries = []

	processing = false

	get deliveryData () {
		return this.deliveries.find(({ alias }) => this.draftOrder.delivery.alias === alias)
	}

	get paymentMethodData () {
		return this.deliveryData.payments[this.draftOrder.paymentMethod.alias]
	}

	async fetchDraftOrder () {
		const DraftOrder = this.app.getEntity('draft-order')
		const data = await DraftOrder.findById(this.params.id, true)
		if (!data) this.error = true
		else {
			this.draftOrder = data
			this.deliveries = await this.draftOrder.getDeliveries()
		}
	}

	async init () {
		await super.init(...arguments)
		await this.app.translator.prefetch('summary')
		await this.fetchDraftOrder()
	}

	goToErrorPage () {
		const errorPath = getPath({
			app: this.app,
			to: 'return',
			query: {
				error: true
			}
		})
		this.app.vue.$router.push(errorPath)
	}

	async submit () {
		this.processing = true

		const { error, status, continueUrl, otfd } = await this.draftOrder.createOrder()
		if (error) return this.goToErrorPage()

		const facebookPixelData = {
			page_title: document.title,
			event_url: location.href,
			content_type: 'product',
			total_price: (this.app.cart.amount || 0) / 100,
			value: (this.app.cart.amount || 0) / 100,
			currency: 'PLN',
			content_ids: this.app.cart.items.map(item => item.product.id),
			user_role: this.app.authorization.isAuthorizated ? 'client' : 'guest',
			contents: this.app.cart.items.map(item => ({
				id: item.product.id,
				quantity: item.quantity,
				name: item.product.name
			})),
			category_name: this.app.cart.items.map(item => item.product.category ? item.product.category.name : 'brak'),
			content_name: this.app.cart.items.map(item => item.product.name)
		}

		window.localStorage.setItem('facebookPixelData', JSON.stringify(facebookPixelData)) // Purchase
		this.app.cart.clear()

		if (status === PaymentMethodContinue.REDIRECT_TO_INTERNAL_PAGE) {
			let orderDate = this.draftOrder.dateCreated
			orderDate = orderDate.substring(0, 10)

			let deliveryDate = new Date(orderDate)
			deliveryDate.setDate(deliveryDate.getDate() + 7)

			deliveryDate = deliveryDate.getFullYear() + '-' + deliveryDate.getMonth() + 1 + '-' + deliveryDate.getDate()

			const trustedShopsData = {
				id: this.draftOrder.id,
				email: this.draftOrder.client.email,
				amount: this.draftOrder.cart.amount,
				currency: 'PLN',
				paymentMethod: this.draftOrder.paymentMethod.alias,
				deliveryDate: deliveryDate

			}

			const klaviyoClientData = {
				email: this.draftOrder.client.email,
				first_name: this.draftOrder.client.firstName,
				last_name: this.draftOrder.client.lastName
			}

			const thankYouPagePath = getPath({
				app: this.app,
				to: 'return',
				query: {
					otfd,
					trustedShopsData,
					klaviyoClientData
				}
			})
			this.app.vue.$router.push(thankYouPagePath)
		} else if (status === PaymentMethodContinue.REDIRECT_TO_CONTINUE_URL) {
			window.location.href = continueUrl
		}

		this.processing = false
	}
}

export { SummaryPage }
