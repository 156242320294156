<template>
	<base-container>
		<section class="summary-wrapper" v-if="draftOrder">
			<base-row>
				<base-col col="12" lg="7">
					<divider-heading>{{langs.titles.products}}</divider-heading>
					<div class="ordered-items-wrapper">
						<cart-item
							class="ordered-item"
							v-for="item in draftOrder.cart.items"
							:key="item.product.id"
							:item="item"
						/>
					</div>
					<divider-heading withEdit>{{langs.titles.shipment}}</divider-heading>
					<summary-methods :method="langs.methods[draftOrder.delivery.alias]" :price="draftOrder.summary.deliveryCost">
						<summary-delivery-inpost
							v-if="draftOrder.delivery.alias === 'inpost'"
							:point="draftOrder.delivery.point"
						/>
						<base-font v-else>{{deliveryData.data.info}}</base-font>
					</summary-methods>
					<divider-heading withEdit>{{langs.titles.payment}}</divider-heading>
					<summary-methods :method="langs.methods[draftOrder.paymentMethod.alias]" :price="draftOrder.summary.paymentCost">
						<base-font>{{paymentMethodData.info}}</base-font>
					</summary-methods>
				</base-col>
				<base-col col="12" offset-lg="1" lg="4">
					<divider-heading>{{langs.titles.summary}}</divider-heading>
					<price-wrapper :cart="draftOrder.cart" extendedInfo :toPay="draftOrder.summary.totalAmount" :additionalCost="draftOrder.summary.additionalCost"/>
					<divider-heading withEdit>{{langs.titles.client_data}}</divider-heading>
						<div class="client-data">
							<base-font size="sm" weight="bold" class="client-data-heading" >{{langs.data_headers.address}}</base-font>
							<ul class="summary-list">
								<li><base-font>{{draftOrder.client.firstName}} {{draftOrder.client.lastName}}</base-font></li>
								<li><base-font>{{draftOrder.client.streetAndBuildingNumber}}</base-font></li>
								<li><base-font>{{draftOrder.client.postalCode}}, {{draftOrder.client.city}}</base-font></li>
								<li><base-font>e-mail: {{draftOrder.client.email}}</base-font></li>
								<li><base-font>tel: {{draftOrder.client.phoneNumber}}</base-font></li>
							</ul>
							<div v-if="draftOrder.invoice">
								<base-font size="sm" weight="bold" class="client-data-heading" >{{langs.data_headers.account_details}}</base-font>
								<ul class="summary-list" >
									<li v-if="draftOrder.invoice.invoiceType === 'privatePerson'"><base-font>{{draftOrder.invoice.name}} {{draftOrder.invoice.lastname}}</base-font></li>
									<template v-else>
										<li><base-font>{{draftOrder.invoice.companyName}}</base-font></li>
										<li><base-font>NIP: {{draftOrder.invoice.companyNIP}}</base-font></li>
									</template>
									<li><base-font>{{draftOrder.invoice.address}}</base-font></li>
									<li><base-font>{{draftOrder.invoice.postalCode}}, {{draftOrder.invoice.city}}</base-font></li>
								</ul>
							</div>
						</div>
					<divider-heading>{{langs.titles.need_help}}</divider-heading>
						<ul class="summary-list">
							<li class="need-help-item"
								v-for="item in links"
								:key="item.name"
							>
								<base-link :to="item.to" :query="{section: item.param}" target="_blank" type="gray" underline>
									{{item.name}}
								</base-link>
							</li>
						</ul>
				</base-col>
				<base-col col="12">
					<button-submit class="cart-submit" :loading="$app.page.processing" type="secondary" @click="$app.page.submit()">
						<base-font color="white" tag="span">
							{{buttonText}}
						</base-font>
					</button-submit>
				</base-col>
			</base-row>
		</section>
		<section class="summary-wrapper" v-else-if="error">
			<base-row>
				<base-col col="12">
					<divider-heading alignCenter>
						Płatność została rozpoczęta
					</divider-heading>
					<cms-text class="error-wrapper" :props="{ size: 'xs', tag: 'div' }" value="error" />
					<base-button class="back-btn" to="homepage" type="white">Powrót na stronę główną</base-button>
				</base-col>
			</base-row>
		</section>
	</base-container>
</template>
<script>
import ButtonSubmit from '~/website/front/components/molecules/ButtonSubmit'
import CartItem from '~/shop/front/components/CartItem'
import PriceWrapper from '~/shop/front/components/PriceWrapper'
import DividerHeading from '~/website/front/components/molecules/DividerHeading'
import SummaryMethods from '~/shop/front/components/SummaryMethods'
import SummaryDeliveryInpost from '~/shop/front/components/SummaryMethods/SummaryDeliveryInpost.vue'

export default {
	components: {
		ButtonSubmit,
		SummaryDeliveryInpost,
		DividerHeading,
		PriceWrapper,
		CartItem,
		SummaryMethods
	},
	computed: {
		langs () {
			return this.$app.translator.get('summary')
		},
		links () {
			return this.$app.page.value.summary_links
		},
		draftOrder () {
			return this.$app.page.draftOrder
		},
		error () {
			return this.$app.page.error
		},
		deliveryData () {
			return this.$app.page.deliveryData
		},
		paymentMethodData () {
			return this.$app.page.paymentMethodData
		},
		buttonText () {
			return this.langs.buttons.next[this.draftOrder.paymentMethod.alias] || this.langs.buttons.next_step
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('summary')
	}
}
</script>
<style lang="scss" scoped >
	.summary-wrapper {
		margin-top: 3rem;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;

		@include media-breakpoint-up (lg) {
			margin: 6rem 0;
			padding-block: 4rem 0;

		}
		.button-submit {
			:deep(.btn) {
				margin-bottom: 2rem;
			}
			@include media-breakpoint-up(lg) {
				justify-content: flex-end;
				:deep(.btn) {
					margin-bottom: 0;
				}
			}
		}
	}
	.ordered-items-wrapper {
		.ordered-item {
			align-items: center;
			text-align: left;
			pointer-events: none;
		}
		@include media-breakpoint-up(xl) {
			padding-left: 11rem;
		}
	}
	.client-data {
		:deep(.client-data-heading) {
			text-align: right;
		}
	}
	.summary-list {
		list-style-type: none;
		text-align: right;

		.need-help-item:not(:last-of-type) {
			margin-bottom: 10px;
		}
	}
	.info-wrapper {
		margin: 3rem auto;
		text-align: left;
	}
	.error-wrapper {
		margin: 3rem auto;
		@include media-breakpoint-up(lg) {
			width: 40%;
		}
	}
	:deep(.back-btn) {
		margin: 3rem auto 0 auto;
		max-width: 330px;
		justify-content: center;
	}

</style>
