<template>
	<div class="info-wrapper">
		<div class="chosen-method">
			<base-svg class="checked-icon" name="checked"/>
			<base-font size="sm" weight="bold">{{method.name}} <span v-if="price">(<base-price :price="price"/>)</span></base-font>
			<base-svg :name="method.svg" class="methods-icon"/>
		</div>
		<slot/>
	</div>
</template>
<script>
export default {
	props: {
		method: Object,
		price: Number
	}
}
</script>
<style lang="scss" scoped>
	.info-wrapper {
		@include media-breakpoint-up(xl) {
			padding-left: 11rem;
		}
		.chosen-method {
			display: flex;
			align-items: center;
			:deep(.checked-icon) {
				height: 20px;
				width: 20px;
				margin-right: 10px;
			}
		}
		.methods-icon {
			margin-left: 10px;
			height: 30px;
			width: 30px;
		}

	}
</style>
