<template>
	<div>
		<base-font size="sm" weight="bold" marginReset>Nazwa paczkomatu:
			<a
				:href="`https://www.google.com/maps/search/?api=1&query=${point.location.latitude}%2C${point.location.longitude}`"
				class="parcel-link"
				target="_blank"
			>
				{{point.name}}
			</a>
		</base-font>
		<base-font>
		Paczka będzie czekała na ciebie pod adresem <span class="address-data">{{point.address.line1}}, {{point.address_details.city}}</span> i będzie tam dostępna przez 48 godzin po tym czasie
		zostanie przewieziona do najbliższego oddziału InPost, gdzie będzie czekała na ciebie przez kolejne 3 dni robocze.
		</base-font>
	</div>
</template>
<script>
export default {
	props: {
		point: Object
	}
}
</script>
<style lang="scss" scoped>
	.parcel-link {
		color: $gold-600;
	}
	.address-data {
		font-weight: bold;
		text-transform: uppercase;
	}
</style>
